import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { ThreeStars } from 'app/components/Icons'
import React, { memo } from 'react'

import { Poi, Props as PoiProps } from './Poi'

export interface Props {
  cta?: ButtonProps
  description?: string
  label?: string
  poiList: PoiProps[]
  title?: string
}

export const InteractiveMap = memo(function InteractiveMap({
  cta,
  description,
  label,
  poiList,
  title,
}: Props) {
  if (!poiList) {
    return null
  }

  return (
    <Container>
      {label ? (
        <FadeInUp>
          <Label>{label}</Label>
        </FadeInUp>
      ) : null}
      {title ? (
        <FadeInUp>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <Line className="line" variant="compact" />
        </FadeInUp>
      ) : null}
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
      {cta ? (
        <FadeInUp>
          <Button {...cta} variant="simple" />
        </FadeInUp>
      ) : null}

      <Map>
        {poiList.map((item, index) => (
          <Poi key={index} {...item} />
        ))}

        <Hotel>
          Hotel Diana
          <ThreeStars />
        </Hotel>
      </Map>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-top: 12.5rem;
  padding-top: 6.25rem;
  text-align: center;

  .line {
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 1199px) {
    margin-top: 7.5rem;
    padding-top: 5rem;
  }

  @media (max-width: 1023px) {
    padding: 3.75rem 1.875rem;
  }

  @media (max-width: 767px) {
    margin-top: 5.625rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.05rem;
  line-height: 1.125rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.875rem;

  b {
    color: ${({ theme }) => theme.colors.variants.primaryLight};
    font-style: italic;
    font-weight: 500;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`

const Description = styled.div`
  max-width: 50.9375rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
  margin: 3.75rem auto 0;

  @media (max-width: 1199px) {
    font-size: 1rem;
    margin-top: 2.5rem;
  }
`

const Map = styled.div`
  width: 100%;
  background: url('/map.jpg') no-repeat center;
  background-size: cover;
  margin-top: 6.25rem;
  padding-bottom: 49%;
  position: relative;

  @media (max-width: 1023px) {
    display: none;
  }
`

const Hotel = styled.div`
  width: 5.25rem;
  height: 5.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1rem;
  padding: 1.25rem;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  top: 42%;
  left: 74%;
  transform: translateY(-2.625rem);
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 0.5625rem solid transparent;
    border-right: 0.5625rem solid transparent;
    border-top: 0.5625rem solid
      ${({ theme }) => theme.colors.variants.primaryLight};
    position: absolute;
    bottom: -0.5625rem;
    left: 50%;
    transform: translateX(-50%);
  }

  svg {
    display: block;
    width: 2.875rem;
    height: 0.5rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-top: 0.3125rem;
  }
`
