import { navigate } from 'gatsby'
import { memo, useEffect } from 'react'

export interface Props {
  redirects: string[]
}

export const Redirects = memo(function Redirects({ redirects }: Props) {
  if (!redirects) {
    return null
  }

  useEffect(() => {
    const pageQueryParam = new URLSearchParams(window.location.search).get(
      'page_id',
    )
    const langQueryParam = new URLSearchParams(window.location.search).get(
      'lang',
    )

    if (pageQueryParam) {
      redirects.some((e: any) => {
        if (e.from_path.includes(`/?page_id=${pageQueryParam}`)) {
          navigate(e.to_path)
        }
      })
    } else if (langQueryParam) {
      redirects.some((e: any) => {
        if (e.from_path.includes(`/?lang=${langQueryParam}`)) {
          navigate(e.to_path)
        }
      })
    }
  }, [])

  return null
})
